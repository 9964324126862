import React from "react";
// import Banner from "../components/common/banner";
import Experience from "../components/ServiceComponent/Experience";

import TechnologyList from "../components/ServiceComponent/technology";
import Servicepageofservice from "../components/ServiceComponent/Servicepageofservice";
import ContactForm from "../components/ServiceComponent/ContactForm";
function ServicePage() {
  return (
    <>
      {/* <Banner title="Service" /> */}
    
        <Experience />
  
     
        <Servicepageofservice/>
      <ContactForm/>
      
        <TechnologyList />
   
    </>
  );
}

export default ServicePage;
