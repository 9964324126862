import React, { useEffect, useState } from "react";
// import Banner from "../../components/common/banner";
import { useForm } from "react-hook-form";
import { Col, Container, Form, Row, Toast } from "react-bootstrap";
import { postData } from "../../api";
import { useNavigate } from "react-router-dom";

function ForgetPassword() {
  const { register, handleSubmit, errors } = useForm();
  let navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState("");


   useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);


  const onSubmit = async (data) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/user-admin/forgetPassword`;

      const result = await postData(url, data);
      console.log("result", result);
      console.log("result.data", result.data);
      if (result.status) {
        setShow(true);
        setTimeout(() => {
          navigation("/reset-password");
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      console.log(err);
      console.log("err", err);
      setMessage("User is not found");
      setAlert(true);
    }
    
  };

  return (
    <>
      {/* <Banner title="Forget Password" /> */}
      <section>
        <div className="login_wrapper admin login">
          <Container>
            <Row className="m-3 ">
              <Col lg={6} md={6} sm={12} className="offset-lg-3">
                <div className="">
                  <div className="title mb-3">
                    <h1 style={{ color: "white" }}> Forget Password</h1>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col lg={12}>
                        <div className="form_box mb-30">
                          <Form.Control
                            required
                            type="text"
                            name="email"
                            placeholder="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            {...register("email", { required: true })}
                          />
                          {errors?.email &&
                            errors?.email.type === "required" && (
                              <span className="text-danger">
                                email is required
                              </span>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <input type="submit" className="btn btn-primary" />
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
            className="toast"
            bg="success"
          >
            <Toast.Body>
              Forget password successfully.new password will come on email
            </Toast.Body>
          </Toast>
          <Toast
            onClose={() => setAlert(false)}
            show={alert}
            delay={3000}
            autohide
            className="toast"
            bg="danger"
          >
            <Toast.Body>{message && message}</Toast.Body>
          </Toast>
        </div>
      </section>
    </>
  );
}
export default ForgetPassword;
