import React from "react";
function Banner(props) {
  return (
    <section>
      {/* <div className="breatcome_area d-flex align-items-center"> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breatcome_title">
                <div className="breatcome_title_inner pb-lg-2">
                  <h1>{props.title}</h1>
                </div>
                {/* <div className="down_Arrow d-flex align-items-center"></div> */}
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </section>
  );
}

export default Banner;
