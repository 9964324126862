import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function ContactAboutUs() {
  return (
    <section className="about_contanct white bg_fixed py-5">
      <div className="container">
        <div className="row align-items-center mt-0">
          <div className="col-xl-7 col-lg-7">
            <div className="about_contanct_text">
              <h1>
                Let’s Talk About Business<br></br>
                Solutions With Us{" "}
              </h1>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 bt_number">
            <div className="right_button">
              <a href="tel:+918866531035" style={{ textDecoration: 'none' }}>
                <button>Join With Us</button>
              </a>
              <span className="d-flex align-items-center ms-5">
                <i className="fa-light fa-phone-flip text-white"></i>
                <h6 className="mt-0 ps-2" style={{ fontWeight: 'lighter' }}>+91 - 88665 31035</h6>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactAboutUs;
