import React, { useEffect, useState } from "react";
// import Banner from "../../components/common/banner";
import { Col, Container, Row, Form, Button, Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { postData, putData } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import AdminHeader from "../../components/common/admin-header";

function AdminCareerEdit() {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);

  let location = useLocation();

  let [id] = useState(new URLSearchParams(location.search).get("id"));

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  useEffect(() => {
    const List = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/career/get`;
      const result = await postData(url, { _id: id });

      if (result.data.data) {
        let data = result.data.data[0];
        setValue("title", data.title);
        setValue("location", data.location);
        setValue("position", data.position);
        setValue("eduction", data.eduction);
        setValue("experience", data.experience);
        setValue("responsibilities", data.responsibilities);
        setValue("type", data.type);
      }
    };
    List();
  }, [id, setValue]);

  const handleContactSubmit = async (data) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/career/update/${id}`;

      const result = await putData(url, data);

      if (result.data.success) {
        setMessage("Opening update successfully");
        setShow(true);
        setTimeout(() => {
          navigation("/admin-career");
        }, 2000);
      } else {
        throw new Error("Opening update failed");
      }
    } catch (err) {
      console.log("err", err);
      setMessage("Opening update failed");
      setAlert(true);

      setTimeout(() => {
        navigation("/admin-career");
      }, 2000);
    }
  };

  return (
    <>
      {/* <Banner title="Admin Career" /> */}
      <Container>
        <AdminHeader />
        <Row className="m-3">
          <Col lg={6} md={6} sm={12} className="offset-lg-3">
            <div className="quote_wrapper">
            <div  className='mb-5'style={{background:'#16c1f7', padding:'5px'}}>
              <h1 className="m-2" style={{color:'#fff'}}> Opening Update</h1>
              </div>
              <Form onSubmit={handleSubmit(handleContactSubmit)} noValidate>
                <Row>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="title"
                        placeholder="Title"
                        style={{border:'1px solid #118ce9'}}
                        {...register("title", { required: true })}
                      />
                      {errors.title && errors.title.type === "required" && (
                        <span className="text-danger">title is required</span>
                      )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="location"
                        placeholder="Location"
                        style={{border:'1px solid #118ce9'}}
                        {...register("location", { required: true })}
                      />
                      {errors.location &&
                        errors.location.type === "required" && (
                          <span className="text-danger">
                            location is required
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="position"
                        placeholder="Position"
                        style={{border:'1px solid #118ce9'}}
                        {...register("position", { required: true })}
                      />
                      {errors.position &&
                        errors.position.type === "required" && (
                          <span className="text-danger">
                            position is required
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="eduction"
                        placeholder="Eduction"
                        style={{border:'1px solid #118ce9'}}
                        {...register("eduction", { required: true })}
                      />
                      {errors.eduction &&
                        errors.eduction.type === "required" && (
                          <span className="text-danger">
                            eduction is required
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30" style={{border:'1px solid #118ce9'}}>
                      <select {...register("type")} className="form-control">
                        <option value="fullTime">Full TIME</option>
                        <option value="partTime">PART TIME</option>
                      </select>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="experience"
                        placeholder="Experience"
                        style={{border:'1px solid #118ce9'}}
                        {...register("experience", { required: true })}
                      />
                      {errors.experience &&
                        errors.experience.type === "required" && (
                          <span className="text-danger">
                            experience is required
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        as="textarea"
                        name="responsibilities"
                        placeholder="Responsibilities"
                        style={{border:'1px solid #118ce9'}}
                        {...register("responsibilities", { required: true })}
                      />
                      {errors.responsibilities &&
                        errors.responsibilities.type === "required" && (
                          <span className="text-danger">
                            responsibilities is required
                          </span>
                        )}
                    </div>
                  </Col>

                  <Col lg={12} className="mt-2">
                    <div className="text_center mt-2">
                      <Button type="submit" className="btn btn-primary">
                        Submit
                      </Button>
                      <Button
                        variant="secondary"
                        className="m-1"
                        onClick={() => navigation(-1)}
                      >
                        Back
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className="toast"
          bg="success"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
        <Toast
          onClose={() => setAlert(false)}
          show={alert}
          delay={3000}
          autohide
          className="toast"
          bg="danger"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
      </Container>
    </>
  );
}

export default AdminCareerEdit;
