import React, { useEffect, useState } from "react";
// import Banner from "../components/common/banner";
import { Button, Card, Col, Container, Form, Modal, Row, Toast } from "react-bootstrap";
import { postData } from "../api";
import '../assets/css/style.css'
// import banner from "../assets/images/aboutUspage/about-us-bg.svg";

function Career() {
  const inputStyle = {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    width: "100%",
  };

  const inputStyleFocus = {
    ...inputStyle,
    border: "none",
    outline: "1px solid #ccc",
  };

  const [career, setCareer] = useState(null);
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showDetails, setShowDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentJobId, setCurrentJobId] = useState(null);

  useEffect(() => {
    const careerList = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/career/get`;
      const result = await postData(url, {});

      if (result.data.data) {
        setCareer(result.data.data);
      }
    };
    careerList();
  }, []);

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("careerId", currentJobId);
      formData.append("firstName", firstName);
      formData.append("email", email);
      formData.append("mobile", mobile);
      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      const url = `${process.env.REACT_APP_BASEURL}/admin/applyjob/create`;
      const result = await postData(url, formData);

      if (result.status) {
        setMessage("Job applied successfully");
        setShow(true);
        setShowModal(false);

        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 3000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage("Job application failed");
      setAlert(true);
      setShowModal(false);

      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 3000);
    }
  };

  const handleToggleDetails = (id) => {
    setShowDetails((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleShowModal = (id) => {
    setCurrentJobId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="breatcome_area" >
      {/* <img src={banner} alt="banner"  className="img-fuild"/> */}
      <div className="text_overlay">
    <h1>Career</h1>
    <p>Home || Career</p>
  </div> </div>
      <section className="job-section pt-50 pb-50">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section_title text-center mb-40">
                <div className="section_main_title">
                <div className="team_text">
                <h4 style={{paddingBottom:'16px'}}>Job Category</h4></div>
                  <h1>Our Latest Job Category</h1>
                </div>
              </div>
              <Row>
              {career && career.length > 0 ? (
                career.map((item, i) => {
                  let responsibilities = item.responsibilities.split("\n");
                  return (
                    <Col lg={6} key={i}>
                    <Card className="mb-3 cardssssss">
                      <Card.Body>
                        <div className="content mt--70">
                        <div className="d-flex align-items-center">
    <h3 className="me-2" >{item.title}</h3>
    <span style={{ fontSize: '18px', marginTop:'12px' 
      ,fontWeight:'800'
    }}>({item.type})</span>
  </div>
                          <br></br>
                          <div className="information">
                          We are looking for a {item.position} with at least {item.experience} years of experience and a minimum education level of {item.eduction}
<br></br>Location :{item.location}
</div>
<div className="buttons">
                          <Button
                            variant="primary"
                            onClick={() => handleToggleDetails(item._id)}
                           style={{marginLeft:'0px' , marginTop:'35px',backgroundColor:'#118ce9',    border:' none !important'}}
                          >
                            {showDetails[item._id] ? 'Show Less' : 'Read More'}
                          </Button>
                          <Button
                            variant="success"
                            onClick={() => handleShowModal(item._id)}
                            style={{marginTop:'35px',backgroundColor:'#118ce9',border:'none'}}
                          >
                            Apply
                          </Button></div>
                          {showDetails[item._id] && (
                            <div className="responsibilities mt-3">
                              <h5 style={{ fontWeight: "bolder" }}>Responsibilities :-</h5>
                              <div className="p-2">
                                {responsibilities.map((x, index) => (
                                  <p className="string" key={index}>
                                    {x}
                                  </p>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Card></Col>
                    
                  );
                })
              ) : (
                <Row className="justify-center p-3">
                  <h4>Data is not available</h4>
                </Row>
              )}</Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Job Apply Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleContactSubmit} style={{border:'1px solid #dee2e6',padding:'20px'}}>
            <Form.Group className="mb-3">
              <Form.Label className="formText">First Name</Form.Label>
              <Form.Control
                type="text"
                value={firstName}
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="formText">Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="formText">Mobile</Form.Label>
              <Form.Control
                type="text"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="formText">Select a CV/Resume</Form.Label>
              <Form.Control
                type="file"
                name="file"
                accept=".pdf,.doc,.docx"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
            </Form.Group>
            <Button type="submit" variant="success">
              Apply
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
        className="toast"
        bg="success"
      >
        <Toast.Body>{message && message}</Toast.Body>
      </Toast>
      <Toast
        onClose={() => setAlert(false)}
        show={alert}
        delay={3000}
        autohide
        className="toast"
        bg="danger"
      >
        <Toast.Body>{message && message}</Toast.Body>
      </Toast>
    </>
  );
}

export default Career;
