import React from "react";
import AboutusContent from "../components/AboutComponent/aboutcontent";

import Banner from "../components/common/banner";
import TeamMember from "../components/AboutComponent/TeamMember";
import Testimonial from "../components/HomeComponent/testimonial";

import Slider_about_us from "../components/AboutComponent/slider";

import Whychooseus from "../components/AboutComponent/Whychooseus";
import ContactAboutUs from "../components/AboutComponent/ContactAboutUs";

function About() {
  return (
    <>
      {/* <Banner title="About Us" /> */}
     
        <AboutusContent />
        <Slider_about_us/>

        <Whychooseus/>
      
        {/* <TeamMember/> */}
   <ContactAboutUs/>
    <Testimonial/>
      
     
      {/* <Team /> */}
      {/* <Footer /> */}
    </>
  );
}

export default About;
