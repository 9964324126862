import React from "react";
// import Banner from "../components/common/banner";
import ProtfolioList from "../components/protfolio/protfolioList";
import Actualportfolio from "../components/actualportfolio/actualportfolio";
function PotfolioPage() {
  return (
    <>
      {/* <Banner title="Portfolio" /> */}

      <ProtfolioList />
      {/* <Actualportfolio /> */}
 
    </>
  );
}

export default PotfolioPage;
