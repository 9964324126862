import React, { useEffect, useState } from "react";
// import Banner from "../../components/common/banner";
import { useForm } from "react-hook-form";
import { Button, Col, Container, Form, Row, Toast } from "react-bootstrap";
import { postData } from "../../api";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const { register, handleSubmit,  getValues,
    formState: { errors },
} = useForm();
  let navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);



//   useEffect(() => {
//     const newPassword = getValues("newPassword");
//     const confirmPassword = getValues("confirmPassword");

//     if (newPassword !== confirmPassword) {
//       setPasswordsMatchError("Passwords do not match");
//     } else {
//       setPasswordsMatchError("");
//     }
//   }, [getValues]);

  const onConfirmPassword = async (data) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/user-admin/resetPassword`;
      const result = await postData(url, data);
      if (result.status) {
        setShow(true);
        setTimeout(() => {
          navigation("/admin");
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      console.log(err);
      console.log("err", err);
      setMessage("User is not found");
      setAlert(true);
      setTimeout(() => {
        navigation("/admin");
      }, 3000);
    }
  };

  return (
    <>
      {/* <Banner title="Reset Password" /> */}
      <section>
        <div className="login_wrapper admin login">
          <Container>
            <Row className="m-3 ">
              <Col lg={6} md={6} sm={12} className="offset-lg-3">
                <div className="">
                  <div className="title mb-3">
                    <h1 style={{ color: "white" }}> Reset Password</h1>
                  </div>
                  <form onSubmit={handleSubmit(onConfirmPassword)}>
                    <Row>
                      <Col lg={12}>
                        <div className="form_box mb-30">
                          <Form.Control
                            required
                            type="Number"
                            name="OTP"
                            placeholder="OTP"
                            {...register("OTP", { required: true })}
                          />
                          {errors?.OTP && errors?.OTP.type === "required" && (
                            <span className="text-danger">OTP is required</span>
                          )}
                        </div>
                      </Col>

                    <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter Password"
                        {...register("password", {
                          required: true,
                          minLength: {
                            value: 5,
                            message: "password should be 5 char long",
                          },
                        })}
                      />

                      {errors.password &&
                        errors.password.type === "required" && (
                          <span className="text-danger">
                            Password is required
                          </span>
                        )}
                      {errors.password &&
                        errors.password.type === "minLength" && (
                          <span className="text-danger">
                            {errors.password.message}
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Entered Password"
                        {...register("confirmPassword", {
                          required: true,
                          minLength: {
                            value: 5,
                            message: "confirmPassword should be 5 char long",
                          },
                          validate: (value) => {
                            const { password } = getValues();
                            return (
                              password === value ||
                              "Password and confirm should match!"
                            );
                          },
                        })}
                      />

                      {errors.confirmPassword &&
                        errors.confirmPassword.type === "required" && (
                          <span className="text-danger">
                            confirmPassword is required
                          </span>
                        )}
                      {errors.confirmPassword &&
                        errors.confirmPassword.type === "minLength" && (
                          <span className="text-danger">
                            {errors.confirmPassword.message}
                          </span>
                        )}
                      {errors.confirmPassword &&
                        errors.confirmPassword.type === "validate" && (
                          <span className="text-danger">
                            {errors.confirmPassword.message}
                          </span>
                        )}
                    </div>
                  </Col>
                    </Row>
                    <input type="submit" className="btn btn-primary" />
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
            className="toast"
            bg="success"
          >
            <Toast.Body>Reset password successfully.</Toast.Body>
          </Toast>
          <Toast
            onClose={() => setAlert(false)}
            show={alert}
            delay={3000}
            autohide
            className="toast"
            bg="danger"
          >
            <Toast.Body>{message && message}</Toast.Body>
          </Toast>
        </div>
      </section>
    </>
  );
}
export default ResetPassword;
