import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Research from '../../assets/images/workProcess/search.png'
import Design from '../../assets/images/Design.png'
import building from '../../assets/images/building.png'
import deliver from '../../assets/images/Deliver.png'

function Counter() {
  return (
    <section>
      <div className="counter_area white bg_fixed py-5">
        <Container>
          <div className="process_text">
          <h1>We Follow Our Work Process</h1>
<h6>Conveniently mesh cooperative services via magnetic outsourcing. Dynamically grow </h6><h6>
value whereas accurate e-commerce parallel quality vectors.</h6>
</div>
          <Row style={{marginTop:'60px'}}>
            <Col lg={3} md={6} sm={12} className="each_process">
              <div className="single_counter text_center ">
               <img src={Research}></img>
                <div className="counter_desc">
                  <h5>Research</h5>
                  <span style={{color: 'white',
    marginTop: '20px'}}>Progressively benchmark for the
prospective results relationships.</span>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12} className="each_process">
            <div className="single_counter text_center ">
               <img src={Design}></img>
                <div className="counter_desc">
                  <h5>Designing</h5>
                  <span style={{color: 'white',
    marginTop: '20px'}}>Efficiently evisculate an grow your
    of mindshare through.</span>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12} className="each_process">
            <div className="single_counter text_center ">
               <img src={building}></img>
                <div className="counter_desc">
                  <h5>Building</h5>
                  <span style={{color: 'white',
    marginTop: '20px'}}>Credibly maintain magnetic
    partnerships whereas users.</span>
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12} className="each_process">
            <div className="single_counter text_center ">
               <img src={deliver}></img>
                <div className="counter_desc">
                  <h5>Deliver</h5>
                  <span style={{color: 'white',
    marginTop: '20px'}}>Rapidiously for enterprise-wide
    niche markets communities.</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Counter;
