import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arrow from '../../assets/images/Arrow 1.png'
function Banner() {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
     
    ],
  };
  return (
    <>
      <section className="service-box">
        <div
          className="slider_area d-flex align-items-center slider8"
          id="home"
        >
          <Container fluid>
            <Row className="justify-content-end">
              <Col lg={6}>
                <div className="single_slider">
                  <div className="slider_content color_black">
                    <div className="slider_text">
                      <div className="slider_text_inner">
                        <h1>
                          Web and Mobile <br />
                          Cloud Solutions
                        </h1>
                      </div>
                      <div className="slider_text_desc pt-40">
                        <p>
                          We have developed over 1000+ web & mobile applications
                          <br />
                          for the world’s leading Startups and  <br></br>Enterprises.
                        </p>
                      </div>
                      <div className="slider_button pt-10 d-flex row">
                        
                       <div className="button col-auto justify-content-center d-flex">
      {/* <Link to="/service">
        How IT Work
        <i className="fa fa-long-arrow-right"></i>
      </Link> */}
      <Link className="link-button active" to="/aboutus">
        About Us
      </Link> 
    </div>
                 <div className="col " style={{border:'none'}}>         <img src={arrow} style={{paddingLeft:'10px'}}></img>
                 </div>                      
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    
       
      </section>
    </>
  );
}

export default Banner;
