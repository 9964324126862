import React ,{useState} from 'react';
import contactUs from '../../assets/images/contanctUspage/contactUs-Form .svg'
import { Controller, useForm } from "react-hook-form";
import { postData } from "../../api";
import PhoneInput from "react-phone-input-2";
import { Col, Container, Row, Form, Button, Toast } from "react-bootstrap";
import arrow from '../../assets/images/Arrow 1.png'
function ContactForm() {
    const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    clearErrors,
  } = useForm();
  console.log("errors", errors);

  const handleContactSubmit = async (data) => {
    const url = `${process.env.REACT_APP_BASEURL}/contact/create`;

    const result = await postData(url, data);

    if (result.status) {
      setShow(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };
  return (
    <div className='contact-us'>
     <Container className='pb-50 pt-50'>
     <Col md={12} lg={6}>
                <div className="quote_wrapper">
                  <Form onSubmit={handleSubmit(handleContactSubmit)} noValidate>
                    <h5 className='text-white'>CONTACT US</h5>
                    <h1 className='text-white'>Don't Hesitate To<br></br>
Contact Us</h1>
                    <Row>
                    <Col lg={6}>
    <div className="form_box mb-30 position-relative">
        <Form.Control
            required
            type="text"
            name="name"
            placeholder="Full Name Here"
            {...register("FullName", { required: true })}
        />
        {errors.FullName && errors.FullName.type === "required" && (
            <span className="text-danger">
                Full Name is required
            </span>
        )}
        <i className="fa-regular fa-user input-icon"></i>
    </div>
</Col>
<Col lg={6}>
    <div className="form_box mb-30 position-relative">
        <Form.Control
            required
            type="email"
            name="email"
            placeholder="Email Address"
            {...register("email", {
                required: true,
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/
            })}
        />
        {errors.email && errors.email.type === "required" && (
            <span className="text-danger">
                Email is required
            </span>
        )}
        {errors.email && errors.email.type === "pattern" && (
            <span className="text-danger">
                Email Should be "abc@example.com" format
            </span>
        )}
        <i className="fa-light fa-envelope-open input-icon"></i>
    </div>
</Col>
                      <Col lg={12}>
                        <div className="form_box text-box mb-30">
                          <Form.Control
                            type='text'
                            name="message"
                            placeholder="Subject"
                            style={{ backgroundColor:'transparent' , border:'none',borderBottom:'1px solid #ffffff50' }}
                            id="message"
                            rows={3}
                            {...register("message", { required: true })}
                          />
                          {errors.message &&
                            errors.message.type === "required" && (
                              <span className="text-danger">
                                Message is required
                              </span>
                            )}
                        </div>
                    
                      </Col>
                      <Col lg={12}>
                        <div className="form_box text-box mb-30">
                          <Form.Control
                            as='textarea'
                            name="message"
                          placeholder="Write a message"
                            style={{ backgroundColor:'transparent' , border:'none',borderBottom:'1px solid #ffffff50',paddingLeft:'0px' }}
                            id="message"
                            rows={3}
                            {...register("message", { required: true })}
                          />
                          {errors.message &&
                            errors.message.type === "required" && (
                              <span className="text-danger">
                                Message is required
                              </span>
                            )}
                            <i class="fa-light fa-pen input-icon"></i>
                        </div>
                    
                      </Col>
                      <Col lg={8}>
                      <div >
                          <Button className='button-Contact'
                            type="submit"
                            variant="light"
                            style={{ border: "1px solid #118ce9" }}
                          >
                         Send Message
                          </Button>
                          <img src={arrow} style={{paddingLeft:'10px'}} ></img>
                         
                        </div>
                      </Col>
                     
                    </Row>
                  </Form>
                </div>
              </Col>
            
     </Container>
    {/* <Container fluid className='position'>
    <Row>
     <Col lg={12}>
                      <iframe className='w-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234878.94898760848!2d72.36660967206655!3d23.097696994689993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e832f450cca5f%3A0xdafe8298cb674733!2sCentillion%20Softech!5e0!3m2!1sen!2sin!4v1716893511459!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </Col>
     </Row>
    </Container> */}
    </div>
  )
}

export default ContactForm;
