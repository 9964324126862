import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import image from '../../assets/images/it_care_image.png';
import circle1 from '../../assets/images/it_care_circle-1.png';
import circle2 from '../../assets/images/it_care_circle-2.png';
import arrow from '../../assets/images/Arrow 1.png';

function It_solution() {
  const navigate = useNavigate();

  const handleRequestDemoClick = () => {
    navigate('/contactus', { state: { scrollToForm: true } });
  };

  return (
    <section>
      <div className='it_solution'>
        <Container>
          <div className="row mt-0">
            <div className="col-lg-8 col-md-8 col-sm-12 it_care_text">
              <div className='it_care_text_inner'>
                <h1>We Care Your any<br />IT Solution</h1>
                <p>Proactively coordinate quality quality vectors<br />
                  vis-a-vis supply chains. Quickly engage client-centric web services.</p>
                <button className='it_care_bt' onClick={handleRequestDemoClick}>
                  Request for Demo
                </button>
                <img src={arrow} style={{ paddingLeft: '10px' }} alt="arrow" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <img src={circle2} className='circle2' alt="circle2" />
              <img src={image} style={{ width: '100%', paddingBottom: '35px' }} alt="it solution"  className='it_image'/>
              <img src={circle1} className='circle1' alt="circle1" />
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default It_solution;
