/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from "react";
// import Banner from "../../components/common/banner";
import AdminHeader from "../../components/common/admin-header";
import { Button, Col, Container, Row, Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteData, postData, getData } from "../../api";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import xlsx from "json-as-xlsx";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useForm } from "react-hook-form";

// let resumeUrl = "https://www.centillionsoftechhrm.com/api/static/userResume"
let resumeUrl = "https://www.centillionsoftechhrm.com/api/static/undefined";

function AdminCareer() {
  let navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const [career, setCareer] = useState(null);
  console.log("career ----------", career);
  const [data, setData] = useState(null);
  const [positions, setPositions] = useState([]);
  console.log("positions  --------", positions);
  console.log("data ------", data);
  const [selectedPosition, setSelectedPosition] = useState("");

  console.log("selectedPosition --------", selectedPosition);
  const [filteredData, setFilteredData] = useState([]);

  const [vacancy, setVacancy] = useState(true);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    const careerList = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/admin/career/get`;
      const result = await postData(url, {});

      if (result.data.data) {
        setCareer(result.data.data);
      }
    };
    careerList();
  }, []);

  // useEffect(() => {
  //   const get = async () => {
  //     const url = `${process.env.REACT_APP_BASEURL}/admin/applyjob/get`;
  //     const result = await getData(url, {});
  //     setData(result.data.data);
  //   };
  //   get();
  // }, []);

  const deleteRecord = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/career/delete/${id}`;

      const result = await deleteData(url, {});

      if (result.data.success) {
        setMessage("vacancy delete successfully");
        setShow(true);
        // setCareer((prevCareer) => prevCareer.filter((item) => item._id !== id));
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage("Inquiry delete failed");
      setAlert(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const deleteJobData = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/applyjob/delete/${id}`;

      const result = await deleteData(url, data);
      console.log("result ---------", result);

      if (result.data.success) {
        setMessage("job data delete successfully");
        setShow(true);
        // setData((prevData) => prevData.filter((item) => item._id !== id));
        setFilteredData((prevData) =>
          prevData.filter((item) => item._id !== id)
        );
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage("job data delete failed");
      setAlert(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const openUploadedFile = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const getFileUrl = (resumeFileName) => {
    // const serverBaseUrl = process.env.REACT_APP_BASEURL;
    const serverBaseUrl = resumeUrl;
    const fileUrl = `${serverBaseUrl}/ ${resumeFileName}`;
    console.log("fileUrl ---------", fileUrl);
    return fileUrl;
  };

  const toggleTable = () => {
    setShowTable(!showTable);
    setVacancy(!vacancy);
  };

  const vanacyData = () => {
    setVacancy(!vacancy);
  };

  const fetchPositions = async () => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/applyjob/get`;
      const result = await getData(url, {});
      setPositions(result.data.data);
    } catch (error) {
      console.error("Error fetching positions:", error);
    }
  };

  useEffect(() => {
    // get();
    fetchPositions();
  }, []);

  const handlePositionChange = () => {
    const newData = selectedPosition
      ? positions.filter((item) => item.careerId.position === selectedPosition)
      : positions;
    console.log("newData --------", newData);
    setFilteredData(newData);
  };

  useEffect(() => {
    handlePositionChange();
  }, [selectedPosition]);

  const uniquePositions = Array.from(
    new Set(positions.map((position) => position.careerId.position))
  );

  const downloadFile = (dataFile) => {
    let data = [
      {
        sheet: "1",
        columns: [
          { label: "firstName", value: "firstName" },
          { label: "email", value: "email" },
          { label: "mobileNumber", value: "mobile" },
          { label: "resume", value: "resume" },
          { label: "position", value: "careerId.position" },
          { label: "Date", value: "formattedDate" },
        ],
        // content: dataFile,
        content: dataFile.map((row) => ({
          ...row,
          formattedDate: moment(row.createdAt).format("DD-MM-YYYY"),
        })),
      },
    ];
    let currentDate = moment().format("DD-MM-YYYY HH:mm:ss");
    let settings = {
      fileName: `UserResume-${currentDate}`,
    };
    xlsx(data, settings);
  };
  const columns = [
    {
      dataField: "firstName",
      text: "First Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "mobileNumber",
      text: "Mobile Number",
      formatter: (cellContent, row) => {
        return `${row.mobile}`;
      },
    },

    {
      dataField: "resume",
      text: "Resume",
      formatter: (cellContent, row) => {
        return `${row.resume}`;
      },
    },
    {
      dataField: "position",
      text: "Position",
      formatter: (cellContent, row) => {
        return `${row.careerId.position}`;
      },
    },
    {
      dataField: "Date",
      text: "Date",
      formatter: (cellContent, row) => {
        const formattedDate = moment(row.createdAt).format("DD-MM-YYYY");
        return `${formattedDate}`;
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => {
        // const fileUrl = getFileUrl(cellContent);
        // console.log('fileUrl -------', fileUrl)
        return (
          <>
            {/* <Button
                variant="primary m-1"
                onClick={() => openUploadedFile(getFileUrl(row.resume))}
              >
                Open
              </Button>
            </> */}

            <Button
              style={{
                background: "transparent",
                border: "0",
              }}
              onClick={() => openUploadedFile(getFileUrl(row.resume))}
            >
              <img
                src="pdf.jpg"
                alt="buttonpng"
                border="0"
                height="20"
                style={{
                  background: "transparent",
                  border: "0",
                  float: "right",
                }}
              />
            </Button>

            <>
              <Button
                variant="danger m-1"
                style={{
                  background: "transparent",
                  border: "0",
                  float: "right",
                }}
                onClick={() => deleteJobData(row._id)}
              >
                <img src="delete.png" alt="buttonpng" border="0" height="20" />
              </Button>
            </>
          </>
        );
      },
    },
  ];

  return (
    <>
      {/* <Banner title="Admin Career" /> */}

      <Container className="mt-4 mb-5">
        <AdminHeader />

        <Row>
          {vacancy && (
            <Col
              md={12}
              className="text-end py-2"
              style={{ paddingRight: "2.5rem" }}
            >
              <Button
                variant="primary"
                className="m-1"
                onClick={() => navigation("/admin-user")}
              >
                Back
              </Button>
              <Button
                variant="primary"
                className="text-start m-1"
                // onClick={toggleTable}
                onClick={() => {
                  handlePositionChange();
                  toggleTable();
                }}
              >
                Resume
              </Button>
              <button
                onClick={() => navigation("/admin-career/create")}
                className="btn btn-primary"
              >
                New Opening
              </button>
            </Col>
          )}
          <section class="pb-4">
            <div class="container">
              <div class="row">
                {career && career.length > 0 ? (
                  career?.map((item, i) => {
                    let string = item.responsibilities.split("\n");
                    return (
                      <div key={i}>
                        {vacancy && (
                          <Accordion defaultActiveKey={i}>
                            <Accordion.Item eventKey={i}>
                              <Accordion.Header>{item.title}</Accordion.Header>
                              <Accordion.Body>
                                <div className="content">
                                  <Button
                                    variant="danger"
                                    className="text-end m-1"
                                    style={{
                                      float: "right",
                                      paddingLeft: "5px",
                                    }}
                                    onClick={() => deleteRecord(item._id)}
                                  >
                                    delete
                                  </Button>
                                  <Button
                                    variant="primary"
                                    className="text-end m-1"
                                    style={{
                                      float: "right",
                                      paddingLeft: "5px",
                                    }}
                                    onClick={() =>
                                      navigation(
                                        "/admin-career/edit?id=" + item._id
                                      )
                                    }
                                  >
                                    Edit
                                  </Button>
                                  <p>Title: {item.title}</p>
                                  <p>location: {item.location}</p>
                                  <p>position: {item.position}</p>
                                  <p>eduction: {item.eduction}</p>
                                  <p>experience: {item.experience}</p>
                                  <p>skill: {item.skill}</p>
                                  <p>type: {item.type}</p>
                                  <h5 style={{ fontWeight: "bolder" }}>
                                    Responsibilities :-
                                  </h5>
                                  <div className="responsibilities p-2">
                                    {string.map((x, i) => (
                                      <p
                                        className="string"
                                        key={i * career.length}
                                      >
                                        {x}
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <Row className="justify-center p-3">
                    <h4> Data is not available</h4>
                  </Row>
                )}
              </div>

              {/*  -----  Table Data  ------ */}
              {showTable && (
                <div className="table-div">
                  <div className="row">
                    <div className="col-12 mt-4 mb-4 text-end">
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <div className="form_box mb-30">
                            <select
                              className="form-select"
                              style={{ backgroundColor: "white" }}
                              value={selectedPosition}
                              onChange={(e) => {
                                setSelectedPosition(e.target.value);
                                handlePositionChange();
                              }}
                            >
                              <option value="">Select Position</option>
                              {uniquePositions.map((position, index) => (
                                <option key={index} value={position}>
                                  {position}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 mb-4">
                          <Button onClick={() => toggleTable()}>Back</Button>
                          <Button onClick={() => downloadFile(filteredData)}>
                            Export
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <BootstrapTable
                    bootstrap4
                    keyField="_id"
                    data={data && data}
                    // data={filteredData}
                    columns={columns}
                    pagination={paginationFactory({ sizePerPage: 5 })}
                  /> */}
                  <div className="table-container">
                    <BootstrapTable
                      bootstrap4
                      keyField="_id"
                      data={filteredData}
                      columns={columns}
                      // pagination={paginationFactory({ sizePerPage: 10 })}
                      pagination={paginationFactory({
                        sizePerPage: 10,
                        sizePerPageList: [5, 10, 25, 50],
                        classes: "custom-pagination-class",
                      })}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        </Row>
      </Container>
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
        className="toast"
        bg="success"
      >
        <Toast.Body>{message && message}</Toast.Body>
      </Toast>
      <Toast
        onClose={() => setAlert(false)}
        show={alert}
        delay={3000}
        autohide
        className="toast"
        bg="danger"
      >
        <Toast.Body>{message && message}</Toast.Body>
      </Toast>
    </>
  );
}

export default AdminCareer;
