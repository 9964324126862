import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import first from '../../assets/images/college_slider/college_slider1.jpg';
import second from '../../assets/images/college_slider/college_slider2.svg';
import third from '../../assets/images/college_slider/college_slider3.svg';
import fourth from '../../assets/images/Rectangle 17 (1).svg';
import fifth from '../../assets/images/Rectangle 17.svg';
import sixth from '../../assets/images/Rectangle 17 (2).svg'

const ImageSlider = () => {
    // State to hold the images to show
    const [images, setImages] = useState([]);

    // Function to determine the images to show based on screen width
    const getImagesToShow = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 768) {
            return [first, second , third]; // Show first and second images on larger screens
        } else {
            return [fourth, fifth , sixth]; // Show fourth and fifth images on smaller screens
        }
    };

    // Effect to update the images when the window size changes
    useEffect(() => {
        const handleResize = () => {
            const imagesToShow = getImagesToShow();
            setImages(imagesToShow.map((img, index) => ({ img, key: index })));
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the initial images
        handleResize();

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <div className="container slider-container">
            <Slider {...settings}>
                {images.map((image) => (
                    <div key={image.key} className='justify-content-center d-flex pb-50 pl-10'>
                        <img src={image.img} alt={`Slide ${image.key + 1}`} className="slider-image"/>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageSlider;
