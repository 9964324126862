import React from 'react'

import { Col, Container, Row } from "react-bootstrap";
import choose_us from "../../assets/images/aboutUspage/c.jpg.jpg";
import arrow_list from "../../assets/images/aboutUspage/list arrow.svg";

function Whychooseus() {
  return (
    <div className="choose_us">
        <div className="box_choose_us">
          <Container style={{maxWidth:'1000px'}}>
            {" "}
            <div className="text_choose_us">
              <Row>
                <Col lg={6} style={{ border: "none" }} className='choose_us_title'>
                  <h4 style={{color:'#118CE9'}} >WHY CHOOSE US</h4>
                  <Row>
                    <Col style={{ border: "none" }}  className='choose_us_title_inner'>
                      <h1>We Are Expert In IT Solutions Services</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ border: "none" }}>
                      <p>
                        {" "}
                        <img src={arrow_list}></img> Our highest priority is to
                        satisfy the customer needs
                      </p>

                      <p>
                        {" "}
                        <img src={arrow_list}></img> Changing requirements
                        should be welcomed
                      </p>

                      <p>
                        {" "}
                        <img src={arrow_list}></img> In giving motivated
                        individuals the environment, support, and trust they
                        need to get the job done
                      </p>

                      <p>
                        {" "}
                        <img src={arrow_list}></img> The most efficient and
                        effective method of conveying information to and within
                        a development team is face-to-face conversation
                      </p>

                      <p>
                        {" "}
                        <img src={arrow_list}></img> Agile methods promote
                        sustainability
                      </p>

                      <p>
                        {" "}
                        <img src={arrow_list}></img> Continuous attention to
                        technical excellence and good design enhances agility
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row>
                    {" "}
                    <Col style={{ border: "none" }}>
                      {" "}<p>
                      We provides flexible timing, decent pay and a healthy
                      environment to work in Centillion Softech. Our decisions
                      and actions demonstrate Our Values we believe in putting
                      our Values into practice that creates long-term benefits
                      for our customers and employees. Diversity at Centillion
                      Softech brings together clients, employees, and partners
                      on a common global platform. We strongly believe that
                      diversity is critical to innovation and it is the central
                      part of the culture across Centillion Softech's family.</p>
                    </Col>
                  </Row>
                  <img src={choose_us} style={{ width: "100%" }} className='whyChooseUsimage'></img>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
  )
}

export default Whychooseus;
