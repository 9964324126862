import React, { useEffect, useState } from "react";
// import Banner from "../../components/common/banner";
import { postData, deleteData, putData, getData } from "../../api";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Row,
  Col,
  Modal,
  Badge,
  Form,
  Button,
  Container,
  Toast,
} from "react-bootstrap";
import xlsx from "json-as-xlsx";
import moment from "moment";
import AdminHeader from "../../components/common/admin-header";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

function ContactUsManagement() {
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const [modelShow, setModelShow] = useState(false);
  const [reason, setReason] = useState("");
  const [id, setId] = useState();
  const [viewShow, setviewShow] = useState();
  const [viewId, setViewId] = useState();
  console.log('viewShow ----------', viewShow);
  const [viewdata, setviewData] = useState([]);
  console.log('viewdata  -------', viewdata);
  const navigation = useNavigate();

  const handleClose = () => window.location.reload();

  
  const adminName = localStorage.getItem('name');
  console.log('adminName -------', adminName);

  const handleShow = (id) => {
    console.log(id);
    setId(id);
    setModelShow(true);
  };

  const handleViewShowData = (id) => {
    console.log(id);
    setViewId(id);
    setviewShow(true);
  };

  const handleViewShow = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/contact/viewinquiry`;
      const result = await postData(url, { contactId: id }); 
      if (result.data.success) {
        setviewData(result.data); 
        setMessage("Inquiry View successfully");
        setShow(true);
      } else {
        throw new Error(result);
      }
      setViewId(id);
      setviewShow(true);
    } catch (err) {
      console.error(err);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    const get = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/contact/getContact`;

      const result = await postData(url, {});
      console.log("result", result);
      setData(result.data.data);
    };
    get();
  }, []);
  
  

  const handleInquirySubmit = async (data) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/contact/inquiry`;
      const newdata ={
          reason : reason,
          contactId : id,
          adminname : adminName
      }
      const result = await postData(url, newdata);

      if (result.status) {
        setShow(true);
        setMessage('Reason added successfully');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage('Reason Do not add a properly');
      setAlert(true);

      setTimeout(() => {
        navigation("/admin-contact");
      }, 2000);
    }
  };


 
  const handleDelete = async (formData) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/contact/delete/${id}`;
      const result = await deleteData(url, formData);

      if (result.data.success) {
        setMessage("Inquiry delete successfully");
        setShow(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage("Inquiry delete failed");
      setAlert(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const downloadFile = (dataFile) => {
    let data = [
      {
        sheet: "1",
        columns: [
          { label: "firstName", value: "firstName" },
          { label: "lastName", value: "lastName" },
          { label: "email", value: "email" },
          { label: "mobileNumber", value: "mobileNumber" },
          { label: "company", value: "company" },
          { label: "message", value: "message" },
          { label: "isCompleted", value: "isCompleted" },
          { label: "isDeleted", value: "isDeleted" },
          {
            label: "Date",
            value: (rowData) => moment(rowData.createdAt).format("DD-MM-YYYY"),
          },
          ,
        ],
        content: dataFile,
      },
    ];
    let currentDate = moment().format("DD-MM-YYYY HH:mm:ss");
    let settings = {
      fileName: `ContactUs-${currentDate}`,
    };
    xlsx(data, settings);
  };

  const columns = [
    {
      dataField: "firstName",
      text: "First Name",
    },
    {
      dataField: "lastName",
      text: "Last Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "mobileNumber",
      text: "Mobile Number",
      formatter: (cellContent, row) => {
        return `${row.mobileNumber}`;
      },
    },
    {
      dataField: "company",
      text: "Company",
    },
    {
      dataField: "message",
      text: "Message",
    },
    // {
    //   dataField: "action",
    //   text: "Status(ON/OFF)",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <>
    //         {row.isCompleted ? (
    //           // <Badge bg="success">Completed</Badge>
    //           <Button bg="success" onClick={() => updateInquiry(row._id)}>
    //             Close
    //           </Button>
    //         ) : row.isDelete ? (
    //           <Badge bg="Danger">Deleted</Badge>
    //         ) : (
    //           // <Badge bg="info">Open</Badge>
    //           <Button bg="info" onClick={() => updateInquiry(row._id)}>
    //             Open
    //           </Button>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      dataField: "action",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            {!row.isCompleted ? (
              <>
                <Button
                  variant="primary m-1"
                  onClick={() => updateInquiry(row._id)}
                  style={{ display: "none" }}
                >
                  {/* <img
                    src="/check.png"
                    alt="buttonPng"
                    border="0"
                    height="20"
                  /> */}
                  Inquiry
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="primary m-1"
                  style={{
                    background: "transparent",
                    border: "0",
                  }}
                  // onClick={() => viewInquiryOpen(row._id)}
                  onClick={() => handleViewShow(row._id)}
                >
                  <img src="eye.png" alt="buttonPng" border="0" height="20" />
                </Button>
              </>
            )}

            <>
              {/* <Button
                  variant="danger m-1"
                  onClick={() => deleteInquiry(row._id)}
                >
                  Delete
                </Button> */}

              <Button
                style={{
                  background: "transparent",
                  border: "0",
                }}
                onClick={() => handleShow(row._id)}
              >
                <img src="close.png" alt="buttonpng" border="0" height="20"
                style={{
                  background: "transparent",
                  border: "0",
                  float: "right",
                }}
                />
              </Button>

              <Button
                style={{
                  background: "transparent",
                  border: "0",
                }}
                onClick={() => deleteInquiry(row._id)}
              >
                <img src="delete.png" alt="buttonpng" border="0" height="20" />
              </Button>
            </>
          </>
        );
      },
    },
  ];

  const deleteInquiry = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/contact/delete/${id}`;

      const result = await deleteData(url, id);

      if (result.data.success) {
        setMessage("Inquiry delete successfully");
        setShow(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage("Inquiry delete failed");
      setAlert(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const updateInquiry = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/contact/getContact/${id}`;

      const result = await getData(url, { isCompleted: true });

      if (result.data.success) {
        setMessage("Inquiry update successfully");
        setShow(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage("Inquiry update failed");
      setAlert(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const updateInquiryOpen = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/contact/update/${id}`;

      const result = await putData(url, { isCompleted: false });

      if (result.data.success) {
        setMessage("Inquiry update successfully");
        setShow(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage("Inquiry update failed");
      setAlert(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };


  return (
    <>
      {/* <Banner title="Admin Contact-us Management" /> */}
      <Container>
        <AdminHeader />
        <div className="m-4">
        <div className="table-container">
          {data && data ? (
            <>
              <div className="export text-end mb-3 py-2">
                <Button
                  variant="primary"
                  className="m-1"
                  onClick={() => navigation("/admin-user")}
                >
                  Back
                </Button>
                <Button onClick={() => downloadFile(data)}>Export</Button>
              </div>
              <div className="table-div">
                <BootstrapTable
                  bootstrap4
                  keyField="_id"
                  data={data && data}
                  columns={columns}
                  // pagination={paginationFactory({ sizePerPage: 10 })}
                  pagination={paginationFactory({
                    sizePerPage: 10,
                    sizePerPageList: [5, 10, 25, 50],
                    classes: 'custom-pagination-class', 
                  })}
                />
              </div>
            </>
          ) : (
            "No data found"
          )}
        </div>
         </div>   
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className="toast"
          bg="success"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
        <Toast
          onClose={() => setAlert(false)}
          show={alert}
          delay={3000}
          autohide
          className="toast"
          bg="danger"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
        <Modal show={modelShow} onHide={handleClose} >
         <div className="contact_wrapper">
          <Modal.Header closeButton>
            <Modal.Title style={{color:'black'}}>Are you sure to close Inquiry !</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(handleInquirySubmit)} noValidate>
              <Row>
                <Col lg={12}>
                  <div className="form_box mb-30">
                    <Form.Control
                      className="form-textarea"
                      required
                      as="textarea"
                      style={{border:'1px solid #f1f1f1'}}
                      {...register("reason", { required: true })}
                      placeholder="write reason to close "
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                    {errors.reason && errors.reason.type === "required" && (
                      <span className="text-danger">Reason is required</span>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
          <Button
              variant="success"
              onClick={handleSubmit(handleInquirySubmit)}
            >
              Submit
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
          </div>
        </Modal>

        <Modal show={viewShow} onHide={handleClose}>
          <div className="contact_wrapper">
          <Modal.Header closeButton>
            <Modal.Title style={{color:'black'}}>ContactUs Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className="contactDetails">
              <Row>
              <table>
              <tbody>
              <tr>
              <th>Reason</th>
              <td>{viewdata?.data?.reason}</td>
              </tr>
              <tr>
              <th>Date</th>
              <td>{formatDate(viewdata?.data?.date)}</td>
              </tr>
              <tr>
              <th>Reason By Name</th>
              <td>{viewdata?.data?.adminname}</td>
              </tr>
              </tbody>
            </table>
              </Row>
          </Modal.Body>
          </div>
        </Modal>
      </Container>
    </>
  );
}
export default ContactUsManagement;
