import React, { useState } from "react";
// import Banner from "../../components/common/banner";
import { Col, Container, Row, Form, Button, Toast } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { postData } from "../../api";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../components/common/admin-header";

const EventCreate = () => {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  console.log("errors", errors);
  const handleContactSubmit = async (data) => {
    try {
      let formData = new FormData();
      formData.append("event", data.event);
      formData.append("isCover", true);
      formData.append("file", data.file[0]);

      const url = `${process.env.REACT_APP_BASEURL}/gallery/gallery?field=gallary`;

      const result = await postData(url, formData);
      console.log("gallery/gallery?field=gallary", result);
      if (result.status) {
        setShow(true);
        setTimeout(() => {
          navigation("/admin-gallery");
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      console.log("err", err);
      setMessage("event create failed");
      setAlert(true);

      setTimeout(() => {
        navigation("/admin-gallery");
      }, 2000);
    }
  };

  return (
    <>
      {/* <Banner title="Admin Event Create" /> */}
      <Container>
        <AdminHeader />
        <Row>
          <Col md={12} className="text-end">
            <div className="export text-end mb-3">
              <Button
                variant="primary"
                className="m-5"
                onClick={() => navigation("/admin-gallery")}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="m-3 ">
          <Col lg={6} md={6} sm={12} className="offset-lg-3">
            <div className="quote_wrapper">
            <div  className='mb-5' style={{background:'#16c1f7', padding:'5px'}}>
              <h1 className="m-2" style={{color:'#fff'}}> Event Create</h1>
              </div>
              <Form
                onSubmit={handleSubmit(handleContactSubmit)}
                noValidate
                type="multipart/form-data"
              >
                <Row>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        style={{ padding: "13px", paddingLeft: "23px" , border:'1px solid #118ce9' }}
                        name="name"
                        placeholder="Event Name"
                        {...register("event", { required: true })}
                      />
                      {errors.event && errors.event.type === "required" && (
                        <span className="text-danger">event is required</span>
                      )}
                    </div>
                  </Col>

                  <Col lg={12} className="mt-2">
                    <div className="form_box ">
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpeg"
                        name="file"
                        className="form-control"
                        placeholder="Enter Password"
                        style={{ padding: "13px", paddingLeft: "23px", border:'1px solid #118ce9' }}
                        {...register("file", {
                          required: true,
                        })}
                      />

                      {errors.file && errors.file.type === "required" && (
                        <span className="text-danger">file is required</span>
                      )}
                    </div>
                  </Col>
                  {/* <Row>
                  <Col lg={12} className="mt-2">
                    <div className="text_center mt-2">
                      <Button type="submit" className="btn btn-primary">
                        Submit
                      </Button>
                    </div>
                    <Button
                        type="button"
                        variant="secondary"
                        style={{ marginLeft: "5px" }}
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                  </Col>
                  </Row> */}
                  <div className="button-row">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => reset()}
                    >
                      Reset
                    </button>
                  </div>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className="toast"
          bg="success"
        >
          <Toast.Body>Event create successfully</Toast.Body>
        </Toast>
        <Toast
          onClose={() => setAlert(false)}
          show={alert}
          delay={3000}
          autohide
          className="toast"
          bg="danger"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
      </Container>
    </>
  );
};
export default EventCreate;
