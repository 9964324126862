import React from "react";
import { Link } from "react-router-dom"; // Import Link
import about from "../../assets/images/aboutUspage/side_about_us_excellence.jpg";
// import banner from "../../assets/images/aboutUspage/about-us-bg.svg";
import arrow from "../../assets/images/Arrow 1.png";

function AboutusContent() {
  return (
    <section>
      <div className="breatcome_area">
        {/* <img src={banner} alt="banner"  className="img-fuild"/> */}
        <div className="text_overlay">
          <h1>About Us</h1>
          <p>Home || About Us</p>
        </div>
      </div>
      <div className="about_area py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6 pr-50">
              <div className="about_thumb">
                <img
                  src={about}
                  alt=""
                  style={{ position: "relative", width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
              <div className="section_title text_left mb-40 mt-3">
                <div className="section_sub_title uppercase mb-3"></div>
                <div className="section_main_title">
                  <h1>
                    When you Hire us,<br></br>
                    You Hire <em>Quality and Excellence</em>
                  </h1>
                </div>
              </div>
              <div className="singel_about_left mb-30">
                <div className="singel_about_left_inner mb-3">
                  <div className="singel-about-content pl-4">
                    <p>
                      Welcome to Centillion, a trusted software development
                      company specializing in enterprise level services for more
                      than 4 years locally and internationally. We offer a full
                      spectrum of software development and IT consulting
                      services to Fortune enterprises.
                    </p>
                    <p>
                      Our innovative solutions help agencies unlock new levels
                      of information integration, enable collaborative
                      governance, and provide knowledge based decision-making
                      tools for our customers to achieve next level of
                      performance. We cater to various needs with a range of
                      services for companies of all sizes.
                    </p>
                  </div>
                </div>
              </div>
              <Link className='link-button' to="/service">
                  <button className='it_care_bt'>Learn More</button>
                </Link>
              <img src={arrow} style={{ paddingLeft: '10px' }} alt="arrow" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutusContent;
