import React from 'react';
import Slider from 'react-slick';
import { Col, Container, Row } from "react-bootstrap";
// import banner from '../../assets/images/portfolio/about-us-bg.svg';
import Girdhar from '../../assets/images/team_member_images/Asset 43.png';
import Meet from '../../assets/images/team_member_images/Asset 44.png';
import Nandlal from '../../assets/images/team_member_images/Asset 45.png';
import hardik from '../../assets/images/team_member_images/Asset 46.png';
import Tusharj from '../../assets/images/team_member_images/Asset 47.png';  
import Ashmi from '../../assets/images/team_member_images/Asset 48.png';
import Akshay from '../../assets/images/team_member_images/Asset 49.png';
import payal from '../../assets/images/team_member_images/Asset 52.png';
import dhiru from '../../assets/images/team_member_images/Asset 53.png';
import piyush from '../../assets/images/team_member_images/Asset 54.png';
import kevalG from '../../assets/images/team_member_images/Asset 55.png';
import gaurav from '../../assets/images/team_member_images/Asset 56.png';
import Mehul from '../../assets/images/team_member_images/Asset 57.png';
import kevalL from '../../assets/images/team_member_images/Asset 58.png';
import Dharvi from '../../assets/images/team_member_images/Asset 59.png';
import Hinal from '../../assets/images/team_member_images/Asset 60.png';
import TusharS from '../../assets/images/team_member_images/Asset 61.png';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function Gallery() {
  const members = [
    {
      img: Dharvi,
      Name: "Dharvi Suthar",
      category: "Sr. Software Engineer",
    },
    {
      img: Girdhar,
      Name: "Girdhar Bhimani",
      category: "Software developer",
    },
    {
      img: Meet,
      Name: "Meet Patel",
      category: ".Net developer",
    },
    {
      img: hardik,
      Name: "Hardik Suthar",
      category: "Jr. Software Developer",
    },
    {
      img: Tusharj,
      Name: "Tushar Joshi",
      category: "Mern Stack",
    },
    {
      img: TusharS,
      Name: "Tushar Savaliya",
      category: "Sr. Software Engineer ",
    },
    {
      img: Akshay,
      Name: "Akshay Bhalodiya",
      category: "Sr. Software Engineer",
    },
    {
      img: payal,
      Name: "Payal Pansuriya",
      category: "Frontend Developer",
    },
    {
      img: dhiru,
      Name: "Dhiru Vadher",
      category: "Software Engineer",
    },
    {
      img: piyush,
      Name: "Piyush Vadher",
      category: "React Developer",
    },
    {
      img: kevalG,
      Name: "Keval Gohil",
      category: "Software Engineer",
    },
    {
      img: gaurav,
      Name: "Gaurav Panchal",
      category: "Software Engineer (Trainee)",
    },
    {
      img: Mehul,
      Name: "Mehul Gaudana",
      category: "Sr. Software Engineer",
    },
    {
      img: kevalL,
      Name: "Keval Lakhlani",
      category: "Frontend Developer",
    },
    {
      img: Nandlal,
      Name: "Nandlal Dobariya",
      category: "Software QA Engineer",
    },
    {
      img: Hinal,
      Name: "Hinal Bhimani",
      category: "Frontend Developer",
    }
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots:false,
    autoplay:true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section>
      <div className="breatcome_area">
        {/* <img src={banner} alt="banner" className="img-fluid" /> */}
        <div className="text_overlay">
          <h1>Gallery</h1>
          <p>Home || Gallery</p>
        </div>
      </div>
      <div className="team">
        <Container className="mt-70">
          <div className="team_text mb-60">
            <h4>GALLERY</h4>
            <h1 className="text-center pt-5">Meet Our Experienced <br />Team Member</h1>
          </div>
          <div className="d-none d-md-block">
            <Row>
              {members.map((member, index) => (
                <Col key={index} sm={12} md={6} lg={3} className="text-align-center justify-content-center d-flex p-0">
                  <div className="cards" style={{ width: '80%', marginBottom: '45px' }}>
                    <div className="cards-image">
                      <img src={member.img} alt={member.Name} style={{ width: '100%', height: 'auto', borderRadius: '20px 20px 0px 0px' }} />
                    </div>
                    <div className="team_text_inner">
                      <div className="team_name">{member.Name}</div>
                      <div className="category">{member.category}</div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="d-md-none">
            <Slider {...sliderSettings}>
              {members.map((member, index) => (
                <div key={index} className="text-align-center justify-content-center d-flex p-0">
                  <div className="cards" style={{ width: '80%', marginBottom: '45px' }}>
                    <div className="cards-image">
                      <img src={member.img} alt={member.Name} style={{ width: '100%', height: 'auto', borderRadius: '20px 20px 0px 0px' }} />
                    </div>
                    <div className="team_text_inner">
                      <div className="team_name">{member.Name}</div>
                      <div className="category">{member.category}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Gallery;
