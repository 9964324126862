import React, { useState } from "react";
// import Banner from "../../components/common/banner";
import { Col, Container, Row, Form, Button, Toast } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { postData } from "../../api";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../../components/common/admin-header";

const PortFolioCreate = () => {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const handleReset = () => {
    reset();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "technology",
  });
  const handleContactSubmit = async (data) => {
    console.log('data', data)
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/portfolio/create?field=portfolio`;

      let formData = new FormData();
      formData.append("projectName", data.projectName);
      formData.append("shortDescription", data.shortDescription);
      formData.append("link", data.link ? data.link : "");
      formData.append("longDescription", data.longDescription);
      formData.append("clientFeedback", data.clientFeedback);
      formData.append("file", data.file[0]);
      formData.append("Section", data.Section);
      formData.append("technology", data.technology);

      const result = await postData(url, formData);

      if (result.status) {
        setShow(true);
        setTimeout(() => {
          navigation("/admin-portFolio");
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage(err.response.message);
      setAlert(true);

      setTimeout(() => {
        navigation("/admin-portFolio/create");
      }, 2000);
    }
  };

  return (
    <>
      {/* <Banner title="Admin PortFolio Create" /> */}
      <Container>
        <AdminHeader />
        <Row>
          <Col md={12} className="text-end">
            <div className="export text-end mb-3">
              <Button
                variant="primary"
                className="m-5"
                onClick={() => navigation("/admin-portFolio")}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="m-1">
          <Col lg={6} md={6} sm={12} className="offset-lg-3">
            <div className="quote_wrapper">
              <div
                className="mb-5"
                style={{ background: "#16c1f7", padding: "5px" }}
              >
                <h1 className="m-2" style={{ color: "#fff" }}>
                  {" "}
                  PortFolio Create
                </h1>
              </div>
              <Form onSubmit={handleSubmit(handleContactSubmit)} noValidate>
                <Row>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="projectName"
                        placeholder="projectName"
                        style={{ border: "1px solid #118ce9" }}
                        {...register("projectName", { required: true })}
                      />
                      {errors.projectName &&
                        errors.projectName.type === "required" && (
                          <span className="text-danger">
                            projectName is required
                          </span>
                        )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="shortDescription"
                        placeholder="shortDescription"
                        style={{ border: "1px solid #118ce9" }}
                        {...register("shortDescription", { required: true })}
                      />
                      {errors.shortDescription &&
                        errors.shortDescription.type === "required" && (
                          <span className="text-danger">
                            shortDescription is required
                          </span>
                        )}
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        type="text"
                        name="link"
                        placeholder="Website Link"
                        style={{ border: "1px solid #118ce9" }}
                        {...register("link", { required: false })}
                      />
                      {errors.link && errors.link.type === "required" && (
                        <span className="text-danger">link is required</span>
                      )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <select
                        {...register("Section")}
                        className="form-select"
                        style={{ border: "1px solid #118ce9" }}
                      >
                        <option value="WEB">web Application</option>
                        <option value="APPLICATION">Application</option>
                        <option value="WEBSITE">website</option>
                      </select>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        as="textarea"
                        name="clientFeedback"
                        placeholder="clientFeedback"
                        style={{ border: "1px solid #118ce9" }}
                        {...register("clientFeedback", { required: true })}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-3">
                      <Form.Control
                        type="file"
                        name="file"
                        accept="image/png, image/jpeg"
                        multiple
                        style={{
                          padding: "13px",
                          paddingLeft: "23px",
                          border: "1px solid #118ce9",
                        }}
                        className="form-control"
                        placeholder="Enter Password"
                        {...register("file", {
                          required: true,
                        })}
                      />
                      {/* <span className="text-danger">
                        file type must be jpeg png
                      </span>
                      {errors.file && errors.file.type === "required" && (
                        <span className="text-danger">file is required</span>
                      )} */}
                      {errors.file && errors.file.type === "required" && (
                        <span className="text-danger">file is required</span>
                      )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form_box mb-30">
                      <Form.Control
                        required
                        as="textarea"
                        type="text"
                        name="description"
                        placeholder="description"
                        className="form-textarea"
                        style={{ border: "1px solid #118ce9" }}
                        {...register("longDescription", { required: true })}
                      />
                      {errors.longDescription &&
                        errors.longDescription.type === "required" && (
                          <span className="text-danger">
                            Description is required
                          </span>
                        )}
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="form_box mb-3">
                      <Form
                        onSubmit={handleSubmit(handleContactSubmit)}
                        noValidate
                      >
                        {fields.map((field, index) => (
                          <Row key={field.id} sx={{ alignItems: "center" }}>
                            <Col lg={11}>
                              <div className="form_box mb-3">
                                <Form.Control
                                  type="text"
                                  name={`technology[${index}]`}
                                  placeholder={`Technology `}
                                  {...register(`technology[${index}]`)}
                                  style={{
                                    border: "1px solid #118ce9",
                                    display: "flex",
                                  }}
                                />
                              </div>
                            </Col>
                            <Col lg={1}>
                              <Button
                                style={{
                                  background: "transparent",
                                  border: "0",
                                }}
                                className="ms-0 p-0 mb-3 mt-2"
                                onClick={() => remove(index)}
                              >
                                <img
                                  src="/delete.png"
                                  alt="buttonpng"
                                  border="0"
                                  height="20"
                                />
                              </Button>
                            </Col>
                          </Row>
                        ))}

                        <div className="text_center">
                          <Button
                            type="button"
                            onClick={() => append("")}
                          >
                            Add Technology
                          </Button>
                          {/* <Button type="submit" className="btn btn-primary">Submit</Button> */}
                        </div>
                      </Form>
                    </div>
                  </Col>

                  <Col lg={12}></Col>
                  <div className="text_center">
                    <Button type="submit" className="btn btn-primary">
                      Submit
                    </Button>
                    <Button
                      variant="secondary"
                      className="m-1"
                      onClick={() => handleReset()}
                    >
                      Reset
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          className="toast"
          bg="success"
        >
          <Toast.Body>portfolio create successfully</Toast.Body>
        </Toast>
        <Toast
          onClose={() => setAlert(false)}
          show={alert}
          delay={3000}
          autohide
          className="toast"
          bg="danger"
        >
          <Toast.Body>{message && message}</Toast.Body>
        </Toast>
      </Container>
    </>
  );
};
export default PortFolioCreate;
