import React, { useEffect, useState } from "react";
// import Banner from "../../components/common/banner";
import AdminHeader from "../../components/common/admin-header";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Noimage from "../../assets/images/no-image.png";
import axios from "axios";

let imageUrl = "https://www.centillionsoftechhrm.com/api/static/gallary/";
function AdminGallery() {
  const [gallery, setGallery] = useState();
  let navigation = useNavigate();
  useEffect(() => {
    const galleryList = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/gallery/get`;
      const client = axios.create({
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await client.get(url);
      if (result.data.data) {
        setGallery(result.data.data);
      }
    };
    galleryList();
  }, []);

  return (
    <>
      {/* <Banner title="Admin Gallery" /> */}
      <Container className="mt-4 mb-5">
        <AdminHeader />
        <Row>
          <Col md={12} className="text-end">
            <div className="export text-end mb-3  px-3 py-2">
              <Button
                variant="primary"
                className="m-1"
                onClick={() => navigation("/admin-user")}
              >
                Back
              </Button>
              <button
                onClick={() => navigation("/admin-event-create")}
                className="btn btn-primary"
              >
                New Event
              </button>
            </div>
          </Col>
        </Row>
        <section class="pb-4">
          <div class="container" style={{ marginTop: "-15px" }}>
            <div class="row">
              {gallery && gallery.length > 0 ? (
                gallery?.map((item, i) => {
                  let divider =
                    (i + 1) / 3 === 1 ? (
                      <div class="w-100 d-none d-md-block mt-3"></div>
                    ) : (
                      ""
                    );
                  let url = `/admin-gallery-details?album=${item.event}`;
                  return (
                    <>
                      <div class="col-lg-4 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
                        <div class="services-block-three">
                          <a href={url}>
                            <img
                              src={imageUrl + item?.image_url}
                              class="w-100 shadow-1-strong rounded mb-4 image-hover"
                              alt="Boat on Calm Water"
                              style={{
                                width: "100%",
                                height: "170px",
                                objectFit: "cover",
                              }}
                            />
                            <p>{item.event}</p>
                          </a>
                        </div>

                        {divider}
                      </div>
                    </>
                  );
                })
              ) : (
                <Row className="justify-center">
                  <Col>
                    <div className="col-md-6 image-notfound">
                      <h4> Image not available</h4>

                      <img src={Noimage} alt="" className="img-fluid" />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </section>
      </Container>
    </>
  );
}

export default AdminGallery;
