/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Noimage from "../../assets/images/no_img.png";
import { Col, Container, Form, Row, Button, Toast } from "react-bootstrap";
// import Banner from "../../components/common/banner";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { postData, deleteData, getData } from "../../api";
import AdminHeader from "../../components/common/admin-header";
let imageUrl = "https://www.centillionsoftechhrm.com/api/static/gallary/";

const GalleryDetails = () => {
  const [gallery, setGallery] = useState();
  let location = useLocation();
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  let [albumId] = useState(new URLSearchParams(location.search).get("album"));
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleContactSubmit = async (data) => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("event", albumId);
      for (const key of Object.keys(data.file)) {
        formData.append("file", data.file[key]);
      }

      const url = `${process.env.REACT_APP_BASEURL}/gallery/upload/${albumId}?field=gallary`;

      const result = await postData(url, formData);

      if (result.status) {
        setMessage("Image upload success");
        setShow(true);

        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 3000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      console.log("err", err);
      setMessage("image upload failed");
      setAlert(true);

      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 3000);
    }
  };
  useEffect(() => {
    const galleryList = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/gallery/get?event=${albumId}`;
      const result = await getData(url);
      if (result.data.data) {
        setGallery(result.data.data);
      }
    };
    galleryList();
  }, [albumId]);

  const removeImage = async (id) => {
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASEURL}/gallery/delete/${id}`;

      const result = await deleteData(url);

      if (result.data.success) {
        setMessage("Image Delete Successfully");
        setShow(true);
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(result);
      }
    } catch (err) {
      setMessage("Image Delete failed");
      setAlert(true);

      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <>
      {/* <Banner title="Admin Gallery details" /> */}
      <Container className="mt-3 mb-3">
        <AdminHeader />

        <>
          <Row>
            <Col md={12} className="text-end">
              <div className="export text-end mb-3">
                <Button
                  variant="primary"
                  className="m-3"
                  onClick={() => navigation("/admin-gallery")}
                >
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          {loading ? (
            <Row className="m-3 ">
              <Col lg={6} md={6} sm={12} className="offset-lg-5">
                <span className="loader-spin"></span>
              </Col>
            </Row>
          ) : (
            <>
              <section class="pt-5 pb-5">
                <div class="container">
                  <div className="m-3 text-center text-capitalize">
                    <h1>{albumId}</h1>
                  </div>
                  <div class="row">
                    {gallery && gallery.length > 0 ? (
                      gallery?.map((item, i) => {
                        let divider =
                          (i + 1) / 3 === 1 ? (
                            <div class="w-100 d-none d-md-block mt-3"></div>
                          ) : (
                            ""
                          );
                        return (
                          <>
                            <div class="col-lg-4 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
                              <div class="services-block-three">
                                <a href="#">
                                  <img
                                    src={imageUrl + item?.image_url}
                                    class="w-100 shadow-1-strong rounded mb-4 image-hover"
                                    alt="Boat on Calm Water"
                                    style={{
                                      width: "100%",
                                      height: "170px",
                                      objectFit: "cover",
                                    }}
                                  />

                                  <div className="delete">
                                    <Button
                                      // variant="danger"
                                      onClick={() => removeImage(item._id)}
                                      // disabled={
                                      //   item.isCover === "false" ? false : true
                                      // }
                                      style={{
                                        background: "transparent",
                                        border: "0",
                                      }}
                                    >
                                      {/* Delete */}
                                      <img
                                        src="delete.png"
                                        alt="buttonpng"
                                        border="0"
                                        height="20"
                                      />
                                    </Button>
                                  </div>
                                </a>
                              </div>

                              {divider}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <Col>
                        <div className="col-md-6 image-notfound">
                          <h4> Image not available</h4>

                          <img src={Noimage} alt="" className="img-fluid" />
                        </div>
                      </Col>
                    )}
                  </div>
                </div>
              </section>
            </>
          )}
          <Row className="m-3 ">
            <Col lg={6} md={6} sm={12} className="offset-lg-3">
              <div className="quote_wrapper">
                <div
                  className="mb-5"
                  style={{ background: "#16c1f7", padding: "5px" }}
                >
                  <h1 className="m-2" style={{ color: "#fff" }}>
                    Image Upload
                  </h1>
                </div>
                <Form
                  onSubmit={handleSubmit(handleContactSubmit)}
                  noValidate
                  type="multipart/form-data"
                >
                  <Row>
                    <Col lg={12}>
                      <div className="form_box mb-3">
                        <Form.Control
                          type="file"
                          name="file"
                          accept="image/png, image/jpeg"
                          multiple
                          style={{
                            padding: "13px",
                            paddingLeft: "23px",
                            border: "1px solid #118ce9",
                          }}
                          className="form-control"
                          placeholder="Enter Password"
                          {...register("file", {
                            required: true,
                          })}
                        />

                        {errors.file && errors.file.type === "required" && (
                          <span className="text-danger">file is required</span>
                        )}
                      </div>
                      <div className="text_center">
                        <Button type="submit" className="btn btn-primary">
                          Upload
                        </Button>
                        <Button
                          variant="secondary"
                          className="m-1"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
          {/* {loading ? (
            <Row className="m-3 ">
              <Col lg={6} md={6} sm={12} className="offset-lg-5">
                <span className="loader-spin"></span>
              </Col>
            </Row>
          ) : (
            <>
              <section class="pt-5 pb-5">
                <div class="container">
                  <div className="m-3 text-center text-capitalize">
                    <h1>{albumId}</h1>
                  </div>
                  <div class="row">
                    {gallery && gallery.length > 0 ? (
                      gallery?.map((item, i) => {
                        let divider =
                          (i + 1) / 3 === 1 ? (
                            <div class="w-100 d-none d-md-block mt-3"></div>
                          ) : (
                            ""
                          );
                        return (
                          <>
                            <div class="col-lg-4 col-md-6 margin-30px-bottom xs-margin-20px-bottom">
                              <div class="services-block-three">
                                <a href="#">
                                  <img
                                    src={imageUrl + item?.image_url}
                                    class="w-100 shadow-1-strong rounded mb-4 image-hover"
                                    alt="Boat on Calm Water"
                                    style={{
                                      width: "100%",
                                      height: "170px",
                                      objectFit: "cover",
                                    }}
                                  />

                                  <div className="delete">
                                    <Button
                                      variant="danger"
                                      onClick={() => removeImage(item._id)}
                                      // disabled={
                                      //   item.isCover === "false" ? false : true
                                      // }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </a>
                              </div>

                              {divider}
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <Col>
                        <div className="col-md-6 image-notfound">
                          <h4> Image not available</h4>

                          <img src={Noimage} alt="" className="img-fluid" />
                        </div>
                      </Col>
                    )}
                  </div>
                </div>
              </section>
            </>
          )} */}
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
            className="toast"
            bg="success"
          >
            <Toast.Body>{message && message}</Toast.Body>
          </Toast>
          <Toast
            onClose={() => setAlert(false)}
            show={alert}
            delay={3000}
            autohide
            className="toast"
            bg="danger"
          >
            <Toast.Body>{message && message}</Toast.Body>
          </Toast>
        </>
      </Container>
    </>
  );
};

export default GalleryDetails;
