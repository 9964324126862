import React from "react";
import Banner from "../components/HomeComponent/banner";

import Counter from "../components/HomeComponent/counter";
import Service from "../components/HomeComponent/service";
// import Team from "../components/HomeComponent/team";
import Testimonial from "../components/HomeComponent/testimonial";
import TechnologyList from "../components/ServiceComponent/technology";
import ScrollToTopButton from "./ScrollToTopButton";
import It_solution from "../components/HomeComponent/It_solution";

import Companies_list from "../components/HomeComponent/companies_list";
import ServiceList from "../components/HomeComponent/servicelist";
function HomePage() {
  return (
    <>
      {" "}
    
        <Banner />
     
      <ScrollToTopButton />
   <ServiceList/>
      <It_solution/>
      <Companies_list/>
        <Counter />
        <Service />
      {/* 
      <Team />
      
      <Clients /> */}
      <ScrollToTopButton />
      
        <TechnologyList />

      <ScrollToTopButton />
     
        <Testimonial />
     
    </>
  );
}

export default HomePage;
