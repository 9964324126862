import React, { useState } from "react";
// import Banner from "../../components/common/banner";
import { useForm } from "react-hook-form";
import { Col, Container, Form, Row, Toast } from "react-bootstrap";
import { postData } from "../../api";
import { useNavigate } from "react-router-dom";
import loginImage  from "../../assets/images/logo2.png";

function Login() {
  const { register, handleSubmit, errors } = useForm();

  let navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(null);
  const onSubmit = async (data) => {
    try {
      const url = `${process.env.REACT_APP_BASEURL}/admin/user-admin/login`;

      const result = await postData(url, data);
      console.log("result", result);
      console.log("result.data", result.data);
      if (result) {
        localStorage.setItem("token", result.data?.data?.token);
        localStorage.setItem("name", result.data?.data?.name);
        localStorage.setItem("email", result.data?.data?.email);
        setShow(true);
        setTimeout(() => {
          navigation("/admin-user");
        }, 2000);
      } else {
        alert("login failed");
      }
    } catch (err) {
      console.log(err);
      console.log("err", err);
      setMessage("Login Failed");
      setAlert(true);
    }
  };
  return (
    <>
      {/* <Banner title="Admin Login" /> */}
      <section>
        {/* <div>
          <img src= {logo} style={{width:'100px',height:'100px'}} />
        </div> */}
         {/* <div className="quote_wrapper admin login"> */}
         <div className="login_wrapper admin login">
          <Container>
            <Row className="m-3">
              <Col lg={6} md={6} sm={12} className="d-flex align-items-center mb-4">
                <img src={loginImage} alt="Your Image" style={{width:"500px", height:'300px', borderRadius:'20px'}}  />
              </Col>

              <Col lg={6} md={6} sm={12}>
                  <div className="title mb-3">
                    <h1 style={{ color: "white" }}> Admin Login</h1>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col lg={12}>
                        <div className="form_box mb-30">
                          <Form.Control
                            required
                            type="text"
                            name="email"
                            placeholder="email"
                            {...register("email", { required: true })}
                          />
                          {errors?.email &&
                            errors?.email.type === "required" && (
                              <span className="text-danger">
                                email is required
                              </span>
                            )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="form_box mb-30">
                          <Form.Control
                            required
                            type="password"
                            name="password"
                            placeholder="password"
                            {...register("password", { required: true })}
                          />
                          {errors?.password &&
                            errors?.password.type === "required" && (
                              <span className="text-danger">
                                Password is required
                              </span>
                            )}
                        </div>
                      </Col>
                    </Row>

                    <input
                      type="submit"
                      className="btn btn-primary"
                      style={{ backgroundColor: "#fff", color: "black" }}
                    />
                    <a
                      href="/forget-password"
                      className="text-end"
                      style={{
                        color: "white",
                        padding: "5px",
                        marginLeft: "20px",
                        fontSize: "20px",
                      }}
                    >
                      Forget password
                    </a>
                  </form>
              </Col>
            </Row>
          </Container>
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
            className="toast"
            bg="success"
          >
            <Toast.Body>Login successfully</Toast.Body>
          </Toast>
          <Toast
            onClose={() => setAlert(false)}
            show={alert}
            delay={3000}
            autohide
            className="toast"
            bg="danger"
          >
            <Toast.Body>{message && message}</Toast.Body>
          </Toast>
        </div> 

                
 
      </section>
    </>
  );
}
export default Login;
