import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
// import logo from "../../assets/images/logo.svg";
// import logo from "../../assets/images/main_cslogo.png";
import logo from "../../assets/images/centillion_logo/centillion_logo.png";
function AdminHeader() {
  return (
    <>
      <header>
        <Navbar expand="lg" fixed="top">
          <Container>
            <Navbar.Brand href="/">
            <h6 style={{fontSize:'15px', color: '#118ce9',marginTop:'0px'}}>Admin Portal</h6>
              <img src={logo} alt="Centillion Softech" style={{width:'100%'}} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav >
                <NavLink to="/admin-user" className="nav-link">
                  {/* Admin User */}
                  User
                </NavLink>
                <NavLink to="/admin-contact" className="nav-link">
                  Contact Us
                </NavLink>
                <NavLink to="/admin-gallery" className="nav-link">
                  Gallery
                </NavLink>
                <NavLink to="/admin-portFolio" className="nav-link">
                  {/* Admin portFolio */}
                  PortFolio
                </NavLink>
                <NavLink to="/admin-career" className="nav-link">
                  {/* Admin Career */}
                  Career
                </NavLink>
                <NavLink to="/logout" className="nav-link">
                  Logout
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default AdminHeader;
